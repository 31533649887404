<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Minha empresa > Contrato Social</v-subheader>
      <v-card width="100%">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <v-row>
          <v-col cols="12">
            <k-ask-change-btn></k-ask-change-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="mx-4 pb-4"
              :items-per-page="-1"
              :data="true"
              :headers="headers"
              :items="all"
              :disable-sort="$vuetify.breakpoint.smAndDown"
              hide-default-footer
            >
              <template v-slot:item.dateReference="{ item }">
                {{ item.dateReference | moment('DD/MM/YYYY') }}
              </template>
              <template v-slot:item.actions="{ item }">
                <k-download :id="item.id"></k-download>
              </template>

              <template slot="no-data">
                <p class="text-center blue--text py-6 headline">
                  <v-icon color="blue" medium>info</v-icon> Não há contratos.
                </p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import kHeaderDate from '@src/components/widget/HeaderDate.vue'
import kDownload from '@src/components/widget/Download.vue'
import kAskChangeBtn from '@src/components/widget/AskChangeBtn.vue'

export default {
  name: 'Contract',
  components: {
    kHeaderDate,
    kDownload,
    kAskChangeBtn,
  },
  computed: {
    ...mapState({
      all: (state) => state.company.contracts,
    }),
  },
  data() {
    return {
      headers: [
        {
          text: 'Data',
          align: 'left',
          value: 'dateReference',
        },
        {
          text: 'Tipo',
          value: 'name',
        },
        {
          text: 'Ações',
          align: 'right',
          value: 'actions',
          sortable: false,
        },
      ],
      headerTitle: 'Contrato Social',
      headerDescription: `Acesse o contrato social da sua empresa a qualquer momento.
      Caso precise realizar alguma alteração, clique ao lado e
      solicite um Serviço Adicional.`,
    }
  },
  methods: {
    ...mapActions({
      companyGetContracts: 'company/getContracts',
    }),
  },
  mounted() {
    this.companyGetContracts()
  },
}
</script>
<style scoped></style>
