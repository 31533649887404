<template>
  <span class="k-download">
    <a class="icon" download :href="urlToDonload(id)" target="_blank">
      <v-icon color="grey darken-2" medium>cloud_download</v-icon>
    </a>
  </span>
</template>
<script>
import { fileMethods } from '@state/helpers'

export default {
  name: 'Download',
  props: {
    id: {
      require: true,
      type: Number,
    },
  },
  methods: {
    ...fileMethods,
  },
}
</script>

<style lang="sass" scoped>
.k-download
  a
    text-decoration: none
</style>
